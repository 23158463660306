import React from "react";
import Link from "gatsby-link";

function Footer() {

    return (
        <footer className="bg-green-dark">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    <div className="px-5 py-2">
                        <Link to={'/impressum'} className="text-base text-white hover:text-gray-900">
                            Impressum
                        </Link>
                    </div>

                    <div className="px-5 py-2">
                        <Link to={'/datenschutz'} className="text-base text-white hover:text-gray-900">
                            Datenschutz
                        </Link>
                    </div>
                </nav>
            </div>
        </footer>
    )}
export default Footer;
