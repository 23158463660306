import React, {useEffect, useState} from "react";
import { Transition } from '@headlessui/react'
import Link from "gatsby-link";
import {gql, useLazyQuery} from '@apollo/client';
import {navigate} from 'gatsby';

import vinylWhite from "../images/vinyl-record-svgrepo-com.svg"
import vinylGreen from "../images/vinyl-record-svgrepo-com-green.svg"

function Header () {

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const [showPopup, togglePopup] = useState(false);
  const [myMap, setMyMap] = useState([false, false, false, false, false, false, false, false, false]);
  const updateMap = (k,v) => {
    let myMap = [false, false, false, false, false, false, false, false, false]
    myMap[k] = v
    setMyMap(myMap);
  }


  const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
  query {
    categories ( 
      sort: "id:asc" 
    ) {
      id
      name
      sub_categories {
        id
        name
      }
    }
  }`)
  useEffect(() => {
    loadExpenseStatus( {
      variables: {
        date: data
      }
    })
  }, []);


  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const goToPage = (value) => {
    togglePopup(false)
    navigate(value)
  }

  return (
      <nav className="bg-green-light">

        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between h-20 md:justify-center" aria-label="Global">
            <div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
              <div className="flex items-center justify-between w-full lg:w-auto">
                <Link to={"/"}>
                  <span className="sr-only">Phone Records</span>
                  <img className="h-8 w-auto sm:h-10" src={vinylWhite} alt="Logo"/>
                </Link>
                <div className="-mr-2 flex items-center lg:hidden">
                  <button
                      onClick={()=>setIsOpenMobileMenu(!isOpenMobileMenu)}
                      type="button"
                          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-sliver
                          hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          id="main-menu" aria-haspopup="true">
                    <span className="sr-only">Open main menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:space-x-10">

              {data != undefined && data.categories.map(category => (

              <div key={category.id} className="flex inline-block text-left">
                {myMap[category.id] && (
                    <Transition
                        show={showPopup}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-50 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                      {() => (
                          <div className="z-50 origin-top-right absolute mt-10 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              {category.sub_categories.map(sub => (
                                  <div key={sub.id}
                                       onClick={() => goToPage("/page?category=" + category.id + "&sub=" + sub.id + "&start=0")}
                                     //to={"/page?category=" + category.id + "&sub=" + sub.id}
                                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                     role="menuitem">
                                    {sub.name}
                                  </div>
                              ))}
                            </div>
                          </div>
                      )}
                    </Transition>
                )}
                <div onClick={() => {
                  togglePopup(true)
                  updateMap(category.id, true)
                } }
                   className="border-transparent text-white hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  {category.name}
                </div>
              </div>
              ))}

            </div>
          </nav>
        </div>

        <Transition
            show={isOpenMobileMenu}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
          {() => (

        <div className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={vinylGreen} alt="Logo"/>
              </div>
              <div className="-mr-2">
                <button
                    onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                  </svg>
                </button>
              </div>
            </div>
            <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
              <div className="px-2 pt-2 pb-3" role="none">
                {data != undefined && data.categories.map(category => (
                    <a key={category.id} href="#"
                       className="block px-3 py-2 rounded-md text-base font-medium text-gray-700"
                       role="menuitem">{category.name}
                      {category.sub_categories.map(sub => (
                          <div key={sub.id}
                               onClick={() => {
                                 setIsOpenMobileMenu(!isOpenMobileMenu)
                                 goToPage("/page?category=" + category.id + "&sub=" + sub.id + "&start=0")
                               }}
                              //to={"/page?category=" + category.id + "&sub=" + sub.id}
                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                               role="menuitem">
                            {sub.name}
                          </div>
                      ))}
                    </a>
                    ))}

              </div>
            </div>
          </div>
        </div>
          )}
        </Transition>

      </nav>
  );
}

export default Header;
